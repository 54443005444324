import { WidgetDesignManifestBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetDesign/widgetDesignManifestBuilder';
import { WriteOnlyBuilder } from '@wix/app-manifest-builder/dist/types/types';
import { ElementRoles } from '../../enums';

const tabs = [
  {
    labelKey: 'instagram-blocks.panel.presets.customizeDesign.main',
    ids: [ElementRoles.Widget],
  },
  {
    labelKey: 'instagram-blocks.panel.presets.customizeDesign.header',
    ids: [ElementRoles.HeaderContainer],
  },
];

export function globalDesignBuilder(
  widgetDesignBuilder: WriteOnlyBuilder<WidgetDesignManifestBuilder>,
  t,
) {
  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

  tabs.forEach(({ labelKey, ids }) =>
    widgetDesignTabsBuilder.addTab((tabBuilder) =>
      tabBuilder
        .set({
          label: t(labelKey),
        })
        .groups()
        .set({
          roles: ids,
        }),
    ),
  );
}
