import { ElementRoles } from '../../enums';

const rolesToDisable = [
  ElementRoles.HeaderHashtagBtn,
  ElementRoles.HeaderUserNameBtn,
  ElementRoles.HeaderTitle,
];

export const preventHide = (widgetBuilder, isPrevent = true) => {
  rolesToDisable.forEach((elementId) => {
    widgetBuilder.configureConnectedComponents(elementId, (elementBuilder) =>
      elementBuilder.behavior().set({
        preventHide: isPrevent,
      }),
    );
  });
};
