import { LayoutOptions, LayoutPanelVarNames, LayoutUnits } from '../enums';
import { DesignVariables } from '@wix/platform-editor-sdk';

export const defaultValuesOfDesignVariables: DesignVariables = {
  [LayoutPanelVarNames.TwoRowRS]: 1,
  [LayoutPanelVarNames.TwoRowRE]: 2,
  [LayoutPanelVarNames.TitleCS]: 2,
  [LayoutPanelVarNames.TitleCE]: 3,
  [LayoutPanelVarNames.ButtonsCS]: 3,
  [LayoutPanelVarNames.ButtonsCE]: 4,
  [LayoutPanelVarNames.TitleAlignment]: LayoutOptions.AlignToCenter,
  [LayoutPanelVarNames.ButtonsAlignment]: LayoutOptions.AlignToCenter,
  [LayoutPanelVarNames.HeaderAlignLeft]: {
    value: 1,
    unit: LayoutUnits.fr,
  },
  [LayoutPanelVarNames.HeaderAlignRight]: {
    value: 1,
    unit: LayoutUnits.fr,
  },
  [LayoutPanelVarNames.HeaderAlignLeft]: {
    value: 1,
    unit: LayoutUnits.fr,
  },
  [LayoutPanelVarNames.HeaderAlignRight]: {
    value: 1,
    unit: LayoutUnits.fr,
  },
  [LayoutPanelVarNames.headerTopBottomPadding]: {
    value: 20,
    unit: LayoutUnits.px,
  },
  [LayoutPanelVarNames.headerSidePadding]: {
    value: 20,
    unit: LayoutUnits.px,
  },
  [LayoutPanelVarNames.feedSidePadding]: {
    value: 0,
    unit: LayoutUnits.px,
  },
};
