import { FlowAPI, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { listInstagramAccountMedia } from '@wix/ambassador-instagram-feed-v1-instagram-media/http';
import {
  disconnectAccount,
  getInstagramAccount,
  initiateAccountConnection,
  listInstagramAccounts,
} from '@wix/ambassador-instagram-feed-v1-instagram-account/http';
import {
  CursorQuery,
  ListInstagramAccountMediaResponse,
} from '@wix/ambassador-instagram-feed-v1-instagram-media/types';
import {
  DisconnectAccountResponse,
  GetInstagramAccountResponse,
  InitiateAccountConnectionResponse,
  ListInstagramAccountsResponse,
} from '@wix/ambassador-instagram-feed-v1-instagram-account/types';
import {
  ERRORS_MESSAGE,
  getNextCursorAndHasNext,
  handleApplicationError,
} from '../utils/Viewer';
import {
  GetMediasOptions,
  checkIfMediaBlocked,
  setPagination,
} from './apiHelpers';

export const igApi = ({
  errorHandler,
  httpClient,
}: PlatformControllerFlowAPI | FlowAPI) => {
  const { withErrorHandler, getResolvedError } = errorHandler;
  const defaultMessage = 'default';
  const getMessage = (errorType: string) =>
    errorType.includes(ERRORS_MESSAGE.ENTITY_NOT_FOUND)
      ? 'entityNotFound'
      : errorType.includes(ERRORS_MESSAGE.DEPRECATED_API_VERSION_2) ||
        errorType.includes(ERRORS_MESSAGE.DEPRECATED_API_VERSION_1)
      ? 'deprecatedPersonalAccount'
      : defaultMessage;
  const errorCodesMap = {
    applicationError: {
      403: handleApplicationError,
      190: handleApplicationError,
      401: handleApplicationError,
      100: handleApplicationError,
      NOT_FOUND: () => ({
        message: getMessage(ERRORS_MESSAGE.ENTITY_NOT_FOUND),
      }),
      NO_POSTS_YET: () => ({
        message: 'noPostsYet',
      }),
      12: () => ({
        message: getMessage(ERRORS_MESSAGE.DEPRECATED_API_VERSION_2),
      }),
      2635: () => ({
        message: getMessage(ERRORS_MESSAGE.DEPRECATED_API_VERSION_1),
      }),
    },
    serverError: () => ({
      message: defaultMessage,
    }),
  };
  const getMedias = async (
    connectionId: string,
    {
      limit = null,
      isViewMode = true,
      paginationInstance = null,
    }: GetMediasOptions = {},
  ) => {
    try {
      if (isViewMode && paginationInstance) {
        const isBlocked = checkIfMediaBlocked(paginationInstance);
        if (isBlocked) {
          console.log(`[getMedias]: ${isBlocked}`);
          return { media: [] } as ListInstagramAccountMediaResponse;
        }

        const paging = {
          cursorPaging: setPagination(paginationInstance, limit),
        };
        paginationInstance.setIsLoading(true);
        const data = await mediaReq(paging);
        paginationInstance.setCursor(getNextCursorAndHasNext(data));
        return data;
      } else {
        const paging: CursorQuery = limit ? { cursorPaging: { limit } } : {};
        return mediaReq(paging);
      }
    } catch (err) {
      console.error('[getMedias] error', err);
      throw err;
    }

    async function mediaReq(paging: CursorQuery) {
      try {
        return await withErrorHandler<ListInstagramAccountMediaResponse>(
          () =>
            httpClient
              .request<ListInstagramAccountMediaResponse>(
                listInstagramAccountMedia({ connectionId, paging }),
              )
              .then((response) => response.data),
          { errorCodesMap },
        );
      } catch (error) {
        const resolvedError = getResolvedError(error);
        console.error('Error in mediaReq:', resolvedError);
        throw resolvedError;
      }
    }
  };

  const getAllAccounts = async () => {
    try {
      return await withErrorHandler<ListInstagramAccountsResponse>(
        () =>
          httpClient
            .request<ListInstagramAccountsResponse>(listInstagramAccounts({}))
            .then((response) => response.data),
        {
          errorCodesMap: {
            serverError: () => ({
              message: defaultMessage,
            }),
          },
        },
      );
    } catch (error) {
      const resolvedError = getResolvedError(error);
      console.error('Error fetching getAllAccounts:', resolvedError);
      throw resolvedError;
    }
  };

  const getAccountHash = async () => {
    try {
      return await withErrorHandler<InitiateAccountConnectionResponse>(
        () =>
          httpClient
            .request<InitiateAccountConnectionResponse>(
              initiateAccountConnection({}),
            )
            .then((response) => response.data),
        {
          errorCodesMap: {
            serverError: () => ({
              message: defaultMessage,
            }),
          },
        },
      );
    } catch (error) {
      const resolvedError = getResolvedError(error);
      console.error('Error fetching getAccountHash:', resolvedError);
      throw resolvedError;
    }
  };

  const disconnect = async (connectionId: string) => {
    try {
      return await withErrorHandler<DisconnectAccountResponse>(
        () =>
          httpClient
            .request<DisconnectAccountResponse>(
              disconnectAccount({ connectionId }),
            )
            .then((response) => response.data),
        {
          errorCodesMap: {
            serverError: () => ({
              message: defaultMessage,
            }),
          },
        },
      );
    } catch (error) {
      const resolvedError = getResolvedError(error);
      console.error('Error fetching disconnect:', resolvedError);
      throw resolvedError;
    }
  };

  const getAccount = async (connectionId: string) => {
    try {
      return await withErrorHandler<GetInstagramAccountResponse>(
        () =>
          httpClient
            .request<GetInstagramAccountResponse>(
              getInstagramAccount({ connectionId }),
            )
            .then(({ data }) => data),
        { errorCodesMap },
      );
    } catch (error) {
      const resolvedError = getResolvedError(error);
      console.error('Error fetching Instagram account:', resolvedError);
      throw resolvedError;
    }
  };

  return {
    disconnect,
    getAllAccounts,
    getAccount,
    getMedias,
    getAccountHash,
  };
};
