// On our layout panel we use with desktop props names, so, if we change on mobile mode we need to convert the props names to desktop names
// also when we saved new layout and we on mobile mode we need to convert the props names to mobile names

// these functions on the files help us to do that

// function that get props of type  and return it as type (LoadingPropsI & WidgetLayoutPropsI) | (LoadingPropsI_m & WidgetLayoutPropsI_m)
// type 'Partial<Record<LayoutFeedOptionsType, number>> | Partial<Record<LayoutFeedOptionsBooleanType, boolean>>

import {
  CONVERT_DESKTOP_TO_MOBILE,
  CONVERT_MOBILE_TO_DESKTOP,
} from '../../constants';
import {
  LayoutFeedOptionsType,
  LayoutFeedOptionsBooleanType,
} from '../../enums';
import {
  LoadingPropsI,
  WidgetLayoutPropsI,
  LoadingPropsI_m,
  WidgetLayoutPropsI_m,
} from '../../types';

/**
 * Convert initial mobile layout properties to desktop layout properties.
 *
 * This function takes in layout properties and checks whether the current mode is mobile.
 * If mobile, it converts the mobile-specific property names to desktop property names
 * using the `CONVERT_MOBILE_TO_DESKTOP` mapping. It also removes mobile-specific properties
 * after the conversion.
 *
 * @param initLayout - The initial layout properties, which could be either mobile or desktop types.
 * @param isMobile - A boolean indicating whether the current mode is mobile.
 * @returns A new object containing the converted desktop property names and values if in mobile mode,
 * or the original properties if in desktop mode.
 */
export const convertInitMobilePropsToDesktopPropsNames = (
  initLayout:
    | (LoadingPropsI | WidgetLayoutPropsI)
    | (LoadingPropsI_m | WidgetLayoutPropsI_m),
  isMobile: boolean,
):
  | Partial<Record<LayoutFeedOptionsType, number>>
  | Partial<Record<LayoutFeedOptionsBooleanType, boolean>> => {
  if (isMobile) {
    // Convert mobile property names to desktop property names
    for (const key in initLayout) {
      const desktopKey = CONVERT_MOBILE_TO_DESKTOP[key];
      initLayout[desktopKey] = initLayout[key];
    }

    // Remove mobile-specific properties from the layout
    return Object.fromEntries(
      Object.entries(initLayout).filter(
        ([key]) => !(key in CONVERT_MOBILE_TO_DESKTOP),
      ),
    );
  }

  // Return the original layout if not in mobile mode
  return initLayout as
    | Partial<Record<LayoutFeedOptionsType, number>>
    | Partial<Record<LayoutFeedOptionsBooleanType, boolean>>;
};

/**
 * Get the current layout properties based on the mode (mobile or desktop).
 *
 * This function ensures that the layout properties sent to the layout panel
 * use the correct property names based on the current mode. If in mobile mode,
 * it filters out desktop-specific properties and maps mobile property names to desktop property names.
 *
 * @param props - The widget properties containing layout configuration.
 * @param isMobile - A boolean indicating whether the current mode is mobile.
 * @returns The updated properties with correctly mapped property names based on the mode.
 */
export const getCurrentLayoutPropsByMode = (
  props: WidgetProps,
  isMobile: boolean,
) => {
  if (isMobile) {
    const currentProps = props;

    // Filter out desktop-specific properties
    const filteredProps = Object.fromEntries(
      Object.entries(props).filter(
        ([key]) => !(key in CONVERT_DESKTOP_TO_MOBILE),
      ),
    );

    // Replace the old properties with the filtered ones
    Object.assign(currentProps, filteredProps);

    // Map mobile property names to desktop property names for layout panel
    for (const key in props) {
      const mobileKey = CONVERT_MOBILE_TO_DESKTOP[key];
      currentProps[mobileKey] = props[key];
    }

    return currentProps;
  } else {
    // Return the original properties if in desktop mode
    return props;
  }
};

/**
 * Convert desktop property names to mobile property names when saving layout parameters.
 *
 * This function is used when saving layout parameters in mobile mode. It maps desktop
 * property names to their corresponding mobile property names using the `CONVERT_DESKTOP_TO_MOBILE` mapping.
 *
 * @param newProps - The new layout properties in desktop property names.
 * @returns A new object containing the converted mobile property names and values.
 */
export const convertDesktopPropsNameToMobilePropsName = (
  newProps:
    | Partial<Record<LayoutFeedOptionsType, number>>
    | Partial<Record<LayoutFeedOptionsBooleanType, boolean>>,
) => {
  const mobileProps = {};
  for (const key in newProps) {
    const mobileKey = CONVERT_DESKTOP_TO_MOBILE[key];
    mobileProps[mobileKey] = newProps[key];
  }
  return mobileProps;
};
