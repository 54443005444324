export const lightboxWidgetLayouts = {
  containerLayout: {},
  spx: {
    refWidth: 980,
    resolverType: 'scale',
  },
  componentLayout: {
    hidden: false,
    height: {
      type: 'auto',
    },
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 100,
    },
  },
  itemLayout: {
    alignSelf: 'center',
    justifySelf: 'center',
    gridArea: {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
    },
    type: 'GridItemLayout',
  },
  type: 'SingleLayoutData',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

export const lightboxLayouts = {
  containerLayout: {
    type: 'GridContainerLayout',
    rows: [
      {
        type: 'fr',
        value: 1,
      },
    ],
    columns: [
      {
        type: 'fr',
        value: 1,
      },
    ],
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  spx: {
    refWidth: 980,
    resolverType: 'scale',
  },
  componentLayout: {
    hidden: false,
    height: {
      type: 'auto',
    },
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 100,
    },
    maxWidth: {
      type: 'px',
      value: 750,
    },
  },
  itemLayout: {
    type: 'FixedItemLayout',
    alignSelf: 'center',
    justifySelf: 'center',
    margins: {
      bottom: { type: 'px', value: 0 },
      left: { type: 'px', value: 0 },
      right: { type: 'px', value: 0 },
      top: { type: 'px', value: 0 },
    },
  },
  type: 'SingleLayoutData',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;
