import type {
  ComponentRef,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import {
  EditorScriptFlowAPI,
  type FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import { EditorSDKUtils } from './editorSdkUtils';
import {
  APP_DEF_ID,
  EXPAND_MODE_ID,
  EXPAND_MODE_PRESETS,
  TPA_PAGE_ID,
  lightboxLayouts,
  lightboxWidgetLayouts,
} from '../../constants';

const installLightbox = async (
  editorSDK: FlowEditorSDK,
  title: string,
  tpaPageId: string,
  isResponsive: boolean,
) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const popupRef = await editorSDKUtils.addPopupPage({
    title,
    // TODO: change true to isResponsive
    shouldNavigateToPage: false,
    definition: {
      data: {
        appDefinitionId: APP_DEF_ID,
        managingAppDefId: APP_DEF_ID,
        hidePage: false,
        tpaPageId,
        pageBackgrounds: {
          desktop: {
            custom: true,
            isPreset: false,
            ref: {
              type: 'BackgroundMedia',
              color: '#000',
              // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
              // @ts-ignore
              colorOpacity: 0.6,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
            },
          },
          mobile: { custom: false, isPreset: false },
        },
      },
      components: [
        {
          type: 'Container',
          id: EXPAND_MODE_ID,
          skin: 'wysiwyg.viewer.skins.area.RectangleArea',
          layout: {
            height: 600,
            width: 980,
            x: 0,
            y: 0,
          },
          layouts: isResponsive ? lightboxLayouts : undefined,
          componentType: 'wysiwyg.viewer.components.PopupContainer',
          props: {
            type: 'PopupContainerProperties',
          },
        },
      ],
      style: {
        type: 'ComponentStyle',
        style: {
          properties: {
            'alpha-bg': '0.6',
            bg: 'color_15',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            bg: 'theme',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Page',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
        pageId: '',
        compId: '',
      },
    },
  });

  const [lightboxComponent] = await editorSDKUtils.getDocChildren(popupRef);
  return lightboxComponent ?? popupRef;
};

const addWidgetToLightbox = async (
  editorSDK: FlowEditorSDK,
  lightboxRef: ComponentRef,
  isResponsive: boolean,
) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const widgetOptions = {
    widgetId: EXPAND_MODE_ID,
    installationType: 'closed' as WidgetInstallationType,
    layout: {
      fixedPosition: true,
      height: 600,
      width: 980,
      x: 0,
      y: 0,
    },
    layouts: isResponsive ? lightboxWidgetLayouts : undefined,
    scopedPresets: EXPAND_MODE_PRESETS(),
    containerRef: lightboxRef as any,
  };

  await editorSDKUtils.addWidget(widgetOptions);
  await editorSDKUtils.disablePopupsAutoOpen();
};

const checkForLightboxId = async (editorSDK) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const lightboxes = await editorSDKUtils.getLighboxes();
  const [appLightbox] = lightboxes.filter((lb) => lb.tpaPageId === TPA_PAGE_ID);
  if (appLightbox) {
    return appLightbox.id;
  }
};

export const navigateToLightboxEditor = async (editorSDK: FlowEditorSDK) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const lightboxId = await checkForLightboxId(editorSDK);
  if (lightboxId) {
    await editorSDKUtils.navigateToLightbox(lightboxId);
  } else {
    console.log('IG app - No app lightbox found');
  }
};

export const createLightboxWithWidget = async (
  editorSDK: FlowEditorSDK,
  isResponsive: boolean,
  t: EditorScriptFlowAPI['translations']['t'],
) => {
  const lightboxId = await checkForLightboxId(editorSDK);
  if (lightboxId) {
    console.log('Lightbox already exists', { lightboxId });
    return;
  }

  const lightboxRef = await installLightbox(
    editorSDK,
    t('instagram-blocks.pages.name'),
    TPA_PAGE_ID,
    isResponsive,
  );

  await addWidgetToLightbox(editorSDK, lightboxRef, isResponsive);
};

export const deleteLightbox = async (editorSDKUtils) => {
  const lightboxes = await editorSDKUtils.getLighboxes();
  const igLightbox = lightboxes.find(
    (lb) => lb.managingAppDefId === APP_DEF_ID,
  );
  const lightboxId = igLightbox?.id;
  if (lightboxId) {
    console.log('Lightbox removed', { lightboxId, igLightbox });
    await editorSDKUtils.removeLightbox(lightboxId);
  }
};
