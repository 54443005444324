import { LoadingPropsI, LoadingPropsI_m } from '../types';
import { GRID_LIMIT } from './presets';

export const gridPropsOverride: LoadingPropsI_m & LoadingPropsI = {
  mediasOnInitialLoad: GRID_LIMIT,
  mediasOnInitialLoad_m: 4,
  mediasOnLoadMore: GRID_LIMIT,
  mediasOnLoadMore_m: 4,
  shouldLimitImages: true,
  shouldLimitImages_m: true,
  showLoadMore: true,
  showLoadMore_m: true,
};
