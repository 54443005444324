import {
  IS_NEW_LAYOUT,
  LAYOUT_GRID_NUM_OF_COLUMNS,
  LAYOUT_GRID_NUM_OF_ROWS,
  LAYOUT_ITEM_SPACING,
  LAYOUT_MOBILE_GRID_NUM_OF_COLUMNS,
  LAYOUT_MOBILE_GRID_NUM_OF_ROWS,
  LAYOUT_MOBILE_ITEM_SPACING,
  LAYOUT_MOBILE_RATION_SELECTED,
  LAYOUT_MOBILE_SELECTED,
  LAYOUT_MOBILE_SLIDER_ARROW_HORIZONTAL_PADDING,
  LAYOUT_MOBILE_SLIDER_ARROW_SIZE,
  LAYOUT_MOBILE_SLIDER_NUM_OF_ROWS,
  LAYOUT_RATION_SELECTED,
  LAYOUT_SELECTED,
  LAYOUT_SLIDER_ARROW_HORIZONTAL_PADDING,
  LAYOUT_SLIDER_ARROW_SIZE,
  LAYOUT_SLIDER_NUM_OF_ROWS,
} from '../../constants';
import { WidgetPropsI } from '../../types';

const createRerenderObject = <K extends keyof WidgetPropsI>(
  propsArray: K[],
  prev: WidgetPropsI,
  cur: WidgetPropsI,
) => {
  const rerenderObject: Partial<Record<K, boolean>> = {};
  propsArray.forEach((prop: K) => {
    rerenderObject[prop] = cur[prop] !== prev[prop];
  });
  return rerenderObject;
};

const propsThatRerender: (keyof WidgetPropsI)[] = [
  'accountId',
  'templatesData',
  'isClassic',
  'mediasOnInitialLoad',
  'shouldLimitImages',
  'mediasOnInitialLoad_m',
  'shouldLimitImages_m',
  LAYOUT_GRID_NUM_OF_COLUMNS,
  LAYOUT_GRID_NUM_OF_ROWS,
  LAYOUT_ITEM_SPACING,
  LAYOUT_SELECTED,
  LAYOUT_RATION_SELECTED,
  LAYOUT_SLIDER_ARROW_HORIZONTAL_PADDING,
  LAYOUT_SLIDER_ARROW_SIZE,
  IS_NEW_LAYOUT,
  LAYOUT_MOBILE_GRID_NUM_OF_COLUMNS,
  LAYOUT_MOBILE_GRID_NUM_OF_ROWS,
  LAYOUT_MOBILE_ITEM_SPACING,
  LAYOUT_MOBILE_SELECTED,
  LAYOUT_MOBILE_RATION_SELECTED,
  LAYOUT_MOBILE_SLIDER_ARROW_HORIZONTAL_PADDING,
  LAYOUT_MOBILE_SLIDER_ARROW_SIZE,
  LAYOUT_SLIDER_NUM_OF_ROWS,
  LAYOUT_MOBILE_SLIDER_NUM_OF_ROWS,
];

export const shouldRenderWidget = (
  prev,
  cur,
): { shouldRender: boolean; changedData: Partial<WidgetPropsI> } => {
  const rerenderChanges = createRerenderObject(propsThatRerender, prev, cur);
  let shouldRender = false;
  const changedData: Partial<WidgetPropsI> = {}; // For bi purpose
  Object.entries(rerenderChanges).forEach(([propName, isUpdated]) => {
    if (isUpdated) {
      shouldRender = true;
      changedData[propName] = cur[propName];
    }
  });

  return { shouldRender, changedData };
};

export const updateLoadMore = (
  cur: WidgetPropsI,
  prev: WidgetPropsI,
): { changed: boolean; val?: boolean } => {
  const didLoadMoreChangeDesktop = cur.showLoadMore !== prev.showLoadMore;
  const didLoadMoreChangeMobile = cur.showLoadMore_m !== prev.showLoadMore_m;
  if (didLoadMoreChangeDesktop) {
    return { val: cur.showLoadMore, changed: true };
  } else if (didLoadMoreChangeMobile) {
    return { val: cur.showLoadMore_m, changed: true };
  } else {
    return { changed: false };
  }
};
