import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorSDKUtils } from './editorSdkUtils';
import {
  getInitialLoadingProps,
  getInitialLayoutProps,
  getPresetNameById,
  mainWidgetPresets,
  WidgetPreset,
} from '../../constants';
import { PresetIds, PresetNames } from '../../enums';
import { WidgetPropsI } from '../../types';
import { isMobileViewport } from './modifyAppManifest';
import { checkIfLoadMorePreset } from './presetChangeUtils';

type PresetListenerParams = {
  event: any;
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
};

export async function presetListenerCallback({
  event,
  editorSDK,
  flowAPI,
}: PresetListenerParams) {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const { preset: presetId, componentRef } = event.detail;
  const isMobile = await isMobileViewport(editorSDK);
  const selectedPreset = mainWidgetPresets().find(
    (p) => presetId === p.id || presetId === p.connectedMobilePresetID,
  ) as WidgetPreset;

  const prevPresetId = (await editorSDKUtils.getPreset(componentRef))
    .layout as PresetIds;

  const desktopPresetId = selectedPreset.id;
  const mobilePresetId = selectedPreset.connectedMobilePresetID;
  const desktopPresetName = getPresetNameById(desktopPresetId);
  const mobilePresetName = getPresetNameById(mobilePresetId);

  const isLoadMorePreset = checkIfLoadMorePreset(presetId, prevPresetId);

  const [parentRefComponent] = await editorSDKUtils.getAncestors(componentRef);

  const { mobilePreset } = (await editorSDKUtils.getWidgetProps(
    componentRef,
  )) as WidgetPropsI;

  const shouldChangeMobilePreset = (!isMobile && !mobilePreset) || isMobile;

  await editorSDKUtils.removeOverrides(parentRefComponent, {
    inclusions: { variables: '*' },
  });

  let mobileLayouts = {};
  let updatedPresetProps: {
    preset: PresetNames | null;
    mobilePreset?: PresetNames | null;
  } = {
    preset: desktopPresetName,
  };

  if (shouldChangeMobilePreset) {
    await editorSDKUtils.changePreset(
      parentRefComponent,
      'MOBILE',
      mobilePresetId!,
    );
    mobileLayouts = getInitialLayoutProps(presetId, true);
  }
  if (!isMobile) {
    await editorSDKUtils.changePreset(
      parentRefComponent,
      'DESKTOP',
      desktopPresetId,
    );
  } else {
    updatedPresetProps = {
      ...updatedPresetProps,
      mobilePreset: mobilePresetName,
    };
  }

  const height = isMobile
    ? selectedPreset?.defaultMobileHeight
    : selectedPreset?.defaultHeight;

  if (height) {
    editorSDKUtils.setWidgetHeight(parentRefComponent, height);
  }

  const modifiedProps = {
    ...updatedPresetProps,
    ...getInitialLoadingProps(isLoadMorePreset, isMobile),
    ...getInitialLayoutProps(presetId, isMobile),
    ...mobileLayouts,
  } as Partial<WidgetPropsI>;

  await editorSDKUtils.setWidgetProps({
    componentRef,
    newProps: modifiedProps,
  });
  await editorSDKUtils.reloadManifest();
  return componentRef;
}
