import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import {
  PgCustomOnclick,
  PresetNames,
  LayoutFeed,
  RatioOptions,
} from '../../enums';
import {
  LoadingPropsI,
  LoadingPropsI_m,
  WidgetLayoutPropsI,
  WidgetLayoutPropsI_m,
  WidgetPropsI,
} from '../../types';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/

export interface PropModelType {
  type: any;
  defaultValue: any;
  description: string;
  loadingValue?: any;
}

export type Loading_Props_m_Type = Record<keyof LoadingPropsI_m, PropModelType>;
export type Loading_Props_Type = Record<keyof LoadingPropsI, PropModelType>;
export type Layout_Props_Type = Record<keyof WidgetLayoutPropsI, PropModelType>;
export type Layout_Props_m_Type = Record<
  keyof WidgetLayoutPropsI_m,
  PropModelType
>;

export const layoutProps: Layout_Props_Type = {
  layoutSelected: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: LayoutFeed.Slider,
    description: 'Pro gallery layout type',
  },
  gridNumOfRows: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 2,
    description: 'Pro gallery grid rows number',
  },
  gridNumOfColumns: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 3,
    description: 'Pro gallery grid columns number',
  },
  itemSpacing: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 12,
    description: 'Pro gallery margin between items',
  },
  ratioSelected: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: RatioOptions.option3,
    description: 'Gallery ratio',
  },
  sliderArrowSize: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 50,
    description: 'Slider Arrow size',
  },
  sliderArrowHorizontalPadding: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 32,
    description: 'Slider Arrow horizontal padding',
  },
  isNewLayout: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
    description: 'Is user customize app with new layout panel',
  },
  sliderNumOfRows: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 1,
    description: 'Slider rows number',
  },
};

export const layoutProps_m: Layout_Props_m_Type = {
  layoutSelected_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: LayoutFeed.Slider,
    description: 'Pro gallery layout type',
  },
  gridNumOfRows_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 2,
    description: 'Pro gallery grid rows number',
  },
  gridNumOfColumns_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 2,
    description: 'Pro gallery grid columns number',
  },
  itemSpacing_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 12,
    description: 'Pro gallery margin between items',
  },
  ratioSelected_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: RatioOptions.option3,
    description: 'Gallery ratio',
  },
  sliderArrowSize_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 24,
    description: 'Slider Arrow size',
  },
  sliderArrowHorizontalPadding_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 24,
    description: 'Slider Arrow horizontal padding',
  },
  sliderNumOfRows_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 1,
    description: 'Slider rows number',
  },
};

export const loadingProps: Loading_Props_Type = {
  showLoadMore: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
    loadingValue: true,
    description: 'Is load more button is shown',
  },
  mediasOnInitialLoad: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 30,
    loadingValue: 6,
    description: 'Initial posts to show',
  },
  mediasOnLoadMore: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 6,
    loadingValue: 6,
    description: 'Additional posts to fetch',
  },
  shouldLimitImages: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
    loadingValue: true,
    description: 'Is load more button is shown',
  },
};

export const loadingProps_m: Loading_Props_m_Type = {
  showLoadMore_m: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
    loadingValue: true,
    description: 'Is load more button is shown mobile',
  },
  mediasOnInitialLoad_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 4,
    loadingValue: 4,
    description: 'Initial posts to show mobile',
  },
  mediasOnLoadMore_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 4,
    loadingValue: 4,
    description: 'Additional posts to fetch mobile',
  },
  shouldLimitImages_m: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
    loadingValue: true,
    description: 'Is load more button is shown mobile',
  },
};

export const modelProps: Record<keyof WidgetPropsI, PropModelType> = {
  // Here you can define your widget props
  isAccountSynchronized: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
    description: 'is the account synchronized',
  },
  onClickAction: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: PgCustomOnclick.OpenExpand,
    description: 'Opens the lightbox with the expand mode widget',
  },
  accountId: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
    description: 'Contains the id of the ig account',
  },
  preset: {
    type: WidgetPropertyType.STRING,
    defaultValue: PresetNames.OneRow,
    description: 'Contains the current preset',
  },
  mobilePreset: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
    description: 'Contains the current mobile preset',
  },
  isClassic: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
    description: 'Contains the widget environment',
  },
  templatesData: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
    description: 'Stringified object with folderName, userName and mediaCount',
  },
  ...loadingProps,
  ...loadingProps_m,
  ...layoutProps,
  ...layoutProps_m,
};

export default createBlocksModel({
  widgetName: 'Gallery and Header',
  props: modelProps,
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
