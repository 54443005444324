import { ExpandPresets, PresetHeights, PresetIds, PresetNames } from '../enums';
import * as ClassicThumbnails from '../assets/presets/Classic/web';
import * as StudioThumbnails from '../assets/presets/Studio/web';
import * as ClassicMobileThumbnails from '../assets/presets/Classic/mobile';
import * as StudioMobileThumbnails from '../assets/presets/Studio/mobile';

export interface WidgetPreset {
  name: PresetNames;
  id: PresetIds;
  connectedMobilePresetID: PresetIds;
  src: any;
  layout: Record<string, string>;
  defaultHeight?: number;
  defaultMobileHeight?: number;

  localeName: string;
}

export const EXPAND_MODE_PRESETS = (isResponsive = true) => ({
  desktop: {
    layout: isResponsive ? ExpandPresets.Studio : ExpandPresets.Desktop,
    style: isResponsive ? ExpandPresets.Studio : ExpandPresets.Desktop,
  },
  mobile: {
    layout: ExpandPresets.Mobile,
    style: ExpandPresets.Mobile,
  },
});

const layout = { width: '240px' };

export const mainWidgetPresets = (isClassic = true): WidgetPreset[] => {
  const presetsThumbnails = isClassic ? ClassicThumbnails : StudioThumbnails;
  const { OneRow, TwoRows, Grid, SideBySide, Grid2x3, SideBySideRtl } =
    presetsThumbnails;

  return [
    {
      name: PresetNames.OneRow,
      id: PresetIds.OneRow,
      connectedMobilePresetID: PresetIds.OneRow_M,
      layout,
      defaultHeight: PresetHeights.OneRow,
      defaultMobileHeight: PresetHeights.OneRow_M,
      src: OneRow,
      localeName: 'instagram-blocks.panel.presets.oneRow',
    },
    {
      name: PresetNames.SideBySide_RTL,
      id: PresetIds.SideBySide_RTL,
      connectedMobilePresetID: PresetIds.SideBySide_M,
      layout,
      defaultHeight: PresetHeights.SideBySide_RTL,
      defaultMobileHeight: PresetHeights.SideBySide_M,
      src: SideBySideRtl,
      localeName: 'instagram-blocks.panel.presets.sideBySideRtl',
    },
    {
      name: PresetNames.TwoRows,
      id: PresetIds.TwoRows,
      connectedMobilePresetID: PresetIds.TwoRows_M,
      layout,
      defaultHeight: PresetHeights.TwoRows,
      defaultMobileHeight: PresetHeights.TwoRows_M,
      src: TwoRows,
      localeName: 'instagram-blocks.panel.presets.twoRow',
    },
    {
      name: PresetNames.Grid2x3,
      id: PresetIds.Grid2x3,
      defaultHeight: PresetHeights.Grid2x3,
      defaultMobileHeight: PresetHeights.Grid2x2_m,
      connectedMobilePresetID: PresetIds.Grid2x3_M,
      layout,
      src: Grid2x3,
      localeName: 'instagram-blocks.panel.presets.grid',
    },
    {
      name: PresetNames.SideBySide,
      id: PresetIds.SideBySide,
      connectedMobilePresetID: PresetIds.SideBySide_M,
      layout,
      defaultHeight: PresetHeights.SideBySide,
      defaultMobileHeight: PresetHeights.SideBySide_M,
      src: SideBySide,
      localeName: 'instagram-blocks.panel.presets.sideBySide',
    },
  ];
};

export const mobileWidgetPresets = (isClassic = true) => {
  const presetsThumbnails = isClassic
    ? ClassicMobileThumbnails
    : StudioMobileThumbnails;
  const { OneRow, TwoRows, Grid, Slide } = presetsThumbnails;
  return [
    {
      name: PresetNames.OneRow_M,
      id: PresetIds.OneRow_M,
      src: OneRow,
      localeName: 'instagram-blocks.panel.presets.oneRow',
    },
    {
      name: PresetNames.SideBySide_M,
      id: PresetIds.SideBySide_M,
      src: Slide,
      localeName: 'instagram-blocks.panel.presets.slider',
    },
    {
      name: PresetNames.Grid2x3_M,
      id: PresetIds.Grid2x3_M,

      src: Grid,
      localeName: 'instagram-blocks.panel.presets.grid',
    },
    {
      name: PresetNames.TwoRows_M,
      id: PresetIds.TwoRows_M,
      src: TwoRows,
      localeName: 'instagram-blocks.panel.presets.twoRow',
    },
  ];
};

export const getPresetNameById = (presetId: PresetIds): PresetNames | null => {
  for (const key in PresetIds) {
    if (PresetIds[key] === presetId) {
      return PresetNames[key];
    }
  }
  return null;
};

export const GRID_LIMIT = 6;
