import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { PgCustomOnclick } from '../../enums';

export default createBlocksModel({
  widgetName: 'Expand Mode',
  props: {
    onClickAction: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: PgCustomOnclick.NothingHappens,
      description: 'PG action on click',
    },
    isClassic: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: true,
      description: 'Contains the widget environment',
    },
    templatesData: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
      description:
        'Stringified object with folderName, userName and mediaCount',
    },
  },
  methods: {},
  events: {
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
