import { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { FULL_WIDGET_ID } from './ids';
import { PresetIds } from '../enums';

export const mainWidgetOptions = {
  widgetId: FULL_WIDGET_ID,
  installationType: 'closed' as WidgetInstallationType,
  layout: {
    docked: {
      left: {
        px: 0,
        vw: 0,
      },
      right: {
        px: 0,
        vw: 0,
      },
    },
    fixedPosition: false,
    // height: 400,
    width: 980,
    x: 0,
    y: 0,
    scale: 1,
    rotationInDegrees: 0,
  },
  scopedPresets: {
    desktop: {
      layout: PresetIds.OneRow,
      style: PresetIds.OneRow,
    },
    mobile: {
      layout: PresetIds.OneRow_M,
      style: PresetIds.OneRow_M,
    },
  },
  // layouts: {
  //   componentLayout: {
  //     hidden: false,
  //     height: {
  //       type: 'auto',
  //     },
  //     type: 'ComponentLayout',
  //     width: {
  //       type: 'auto',
  //     },
  //   },
  //   itemLayout: {
  //     id: '',
  //     type: 'GridItemLayout',
  //     gridArea: {
  //       columnStart: 1,
  //       columnEnd: 2,
  //       rowStart: 1,
  //       rowEnd: 2,
  //     },
  //     alignSelf: 'stretch',
  //     justifySelf: 'stretch',
  //     margins: {
  //       left: {
  //         type: 'px',
  //         value: 0,
  //       },
  //       right: {
  //         type: 'px',
  //         value: 0,
  //       },
  //       top: {
  //         type: 'px',
  //         value: 0,
  //       },
  //       bottom: {
  //         type: 'px',
  //         value: 0,
  //       },
  //     },
  //   },
  // },
  layouts: {
    componentLayout: {
      // id: '',
      minHeight: {
        type: 'px',
        value: 50,
      },
      hidden: false,
      height: {
        type: 'px',
        value: 500,
      },
      type: 'ComponentLayout',
      width: {
        type: 'percentage',
        value: 100,
      },
    },
    itemLayout: {
      id: '',
      alignSelf: 'start',
      margins: {
        left: {
          type: 'percentage',
          value: 0,
        },
        right: {
          type: 'percentage',
          value: 0,
        },
        top: {
          type: 'px',
          value: 0,
        },
        bottom: {
          type: 'px',
          value: 0,
        },
      },
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      justifySelf: 'start',
      type: 'GridItemLayout',
    },
    containerLayout: {
      // id: '',
      type: 'GridContainerLayout',
      rows: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      columns: [
        {
          type: 'fr',
          value: 1,
        },
      ],
    },
  },
};
