import { ListInstagramAccountMediaResponse } from '@wix/ambassador-instagram-feed-v1-instagram-media/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const localizeDate = (date, locale = 'en-US') => {
  const dateFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return date ? dateFormatter.format(new Date(date)) : '';
};

export const changeDateToLocalized = (item, locale = 'en-US') => ({
  ...item,
  timestamp: localizeDate(item.timestamp, locale),
});

export const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const getIsViewMode = (flowAPI?: PlatformControllerFlowAPI) => {
  // Show both arrows on editor mode
  let isViewMode = true;
  if (flowAPI) {
    const { isViewer, isPreview } = flowAPI?.environment;
    isViewMode = isViewer || isPreview;
  }

  return isViewMode;
};

export const getNextCursorAndHasNext = (
  mediasData: ListInstagramAccountMediaResponse,
): { next: string; hasNext: boolean } => {
  if (mediasData?.paging?.cursors) {
    const {
      paging: {
        hasNext,
        cursors: { next },
      },
    } = mediasData;
    return { next: next as string, hasNext: hasNext as boolean };
  } else {
    // Cursor for templates \ demo will always be false (less than 30 medias)
    return { next: '', hasNext: false };
  }
};

export const checkIfSameLastElement = (
  existingMedias,
  addedMedias,
): boolean => {
  const lastAdded = addedMedias[addedMedias.length - 1];
  const lastExisting = existingMedias[existingMedias.length - 1];
  return JSON.stringify(lastAdded) === JSON.stringify(lastExisting);
};
