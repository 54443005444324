import { LayoutFeed, RatioOptions } from '../enums';

// GLOBAL LAYOUT
export const IS_NEW_LAYOUT = 'isNewLayout';

// DESKTOP LAYOUT
export const LAYOUT_GRID_NUM_OF_ROWS = 'gridNumOfRows';
export const LAYOUT_SLIDER_NUM_OF_ROWS = 'sliderNumOfRows';
export const LAYOUT_GRID_NUM_OF_COLUMNS = 'gridNumOfColumns';
export const LAYOUT_ITEM_SPACING = 'itemSpacing';
export const LAYOUT_SELECTED = 'layoutSelected';
export const LAYOUT_RATION_SELECTED = 'ratioSelected';
export const LAYOUT_SHOW_LOAD_MORE = 'showLoadMore';
export const LAYOUT_GRID_TOTAL_MEDIAS_TO_SHOW = 'mediasOnInitialLoad';
export const LAYOUT_NUM_MEDIAS_LOAD_MORE = 'mediasOnLoadMore';
export const LAYOUT_IS_LIMIT_IMAGES = 'shouldLimitImages';
export const LAYOUT_SLIDER_ARROW_SIZE = 'sliderArrowSize';
export const LAYOUT_SLIDER_ARROW_HORIZONTAL_PADDING =
  'sliderArrowHorizontalPadding';

// MOBILE LAYOUT
export const LAYOUT_MOBILE_GRID_NUM_OF_ROWS = 'gridNumOfRows_m';
export const LAYOUT_MOBILE_SLIDER_NUM_OF_ROWS = 'sliderNumOfRows_m';
export const LAYOUT_MOBILE_GRID_NUM_OF_COLUMNS = 'gridNumOfColumns_m';
export const LAYOUT_MOBILE_ITEM_SPACING = 'itemSpacing_m';
export const LAYOUT_MOBILE_SELECTED = 'layoutSelected_m';
export const LAYOUT_MOBILE_RATION_SELECTED = 'ratioSelected_m';
export const LAYOUT_MOBILE_SHOW_LOAD_MORE = 'showLoadMore_m';
export const LAYOUT_MOBILE_GRID_TOTAL_MEDIAS_TO_SHOW = 'mediasOnInitialLoad_m';
export const LAYOUT_MOBILE_NUM_MEDIAS_LOAD_MORE = 'mediasOnLoadMore_m';
export const LAYOUT_MOBILE_IS_LIMIT_IMAGES = 'shouldLimitImages_m';
export const LAYOUT_MOBILE_SLIDER_ARROW_SIZE = 'sliderArrowSize_m';
export const LAYOUT_MOBILE_SLIDER_ARROW_HORIZONTAL_PADDING =
  'sliderArrowHorizontalPadding_m';

/**
 * NOTE: If you change here the default layout, you should also change the default layout in the following files:
 * - packages/instagram-blocks-app/src/components/Gallery and Header/model.ts
 * */
const GLOBAL_LAYOUT = {
  DESKTOP: {
    [IS_NEW_LAYOUT]: true,
    [LAYOUT_ITEM_SPACING]: 12,
    [LAYOUT_RATION_SELECTED]: RatioOptions.option3,
    [LAYOUT_SLIDER_ARROW_SIZE]: 50,
    [LAYOUT_SLIDER_ARROW_HORIZONTAL_PADDING]: 32,
    [LAYOUT_SLIDER_NUM_OF_ROWS]: 1,
  },
  MOBILE: {
    [IS_NEW_LAYOUT]: true,
    [LAYOUT_MOBILE_ITEM_SPACING]: 12,
    [LAYOUT_MOBILE_RATION_SELECTED]: RatioOptions.option3,
    [LAYOUT_MOBILE_SLIDER_ARROW_SIZE]: 24,
    [LAYOUT_MOBILE_SLIDER_ARROW_HORIZONTAL_PADDING]: 24,
    [LAYOUT_MOBILE_SLIDER_NUM_OF_ROWS]: 1,
  },
};

export const GRID_DEFAULT = {
  DESKTOP: {
    [LAYOUT_SELECTED]: LayoutFeed.Grid,
    [LAYOUT_SHOW_LOAD_MORE]: true,
    [LAYOUT_GRID_TOTAL_MEDIAS_TO_SHOW]: 6,
    [LAYOUT_IS_LIMIT_IMAGES]: true,
    [LAYOUT_GRID_NUM_OF_COLUMNS]: 3,
    [LAYOUT_GRID_NUM_OF_ROWS]: 2,
    [LAYOUT_NUM_MEDIAS_LOAD_MORE]: 6,
  },
  MOBILE: {
    [LAYOUT_MOBILE_SELECTED]: LayoutFeed.Grid,
    [LAYOUT_MOBILE_SHOW_LOAD_MORE]: true,
    [LAYOUT_MOBILE_GRID_NUM_OF_COLUMNS]: 2,
    [LAYOUT_MOBILE_GRID_NUM_OF_ROWS]: 2,
    [LAYOUT_MOBILE_IS_LIMIT_IMAGES]: true,
    [LAYOUT_MOBILE_GRID_TOTAL_MEDIAS_TO_SHOW]: 4,
    [LAYOUT_MOBILE_NUM_MEDIAS_LOAD_MORE]: 4,
  },
};

export const SLIDER_DEFAULT = {
  DESKTOP: {
    [LAYOUT_GRID_NUM_OF_COLUMNS]: 3,
    [LAYOUT_GRID_NUM_OF_ROWS]: 2,
    [LAYOUT_SELECTED]: LayoutFeed.Slider,
    [LAYOUT_SHOW_LOAD_MORE]: false,
    [LAYOUT_GRID_TOTAL_MEDIAS_TO_SHOW]: 30,
    [LAYOUT_NUM_MEDIAS_LOAD_MORE]: 6,
    [LAYOUT_IS_LIMIT_IMAGES]: false,
  },
  MOBILE: {
    [LAYOUT_MOBILE_GRID_NUM_OF_COLUMNS]: 2,
    [LAYOUT_MOBILE_GRID_NUM_OF_ROWS]: 2,
    [LAYOUT_MOBILE_SELECTED]: LayoutFeed.Slider,
    [LAYOUT_MOBILE_SHOW_LOAD_MORE]: false,
    [LAYOUT_MOBILE_GRID_TOTAL_MEDIAS_TO_SHOW]: 4,
    [LAYOUT_MOBILE_NUM_MEDIAS_LOAD_MORE]: 4,
    [LAYOUT_MOBILE_IS_LIMIT_IMAGES]: false,
  },
};

export const DEFAULT_LAYOUT_BY_PRESET = {
  DESKTOP: {
    Grid: {
      ...GLOBAL_LAYOUT.DESKTOP,
      ...GRID_DEFAULT.DESKTOP,
    },
    SideBySide: {
      ...GLOBAL_LAYOUT.DESKTOP,
      ...SLIDER_DEFAULT.DESKTOP,
    },
    OneRow: {
      ...GLOBAL_LAYOUT.DESKTOP,
      ...SLIDER_DEFAULT.DESKTOP,
    },
    TwoRows: {
      ...GLOBAL_LAYOUT.DESKTOP,
      ...SLIDER_DEFAULT.DESKTOP,
      [LAYOUT_SLIDER_NUM_OF_ROWS]: 2,
    },
  },
  MOBILE: {
    Grid: {
      ...GLOBAL_LAYOUT.MOBILE,
      ...GRID_DEFAULT.MOBILE,
    },
    SideBySide: {
      ...GLOBAL_LAYOUT.MOBILE,
      ...SLIDER_DEFAULT.MOBILE,
    },
    OneRow: {
      ...GLOBAL_LAYOUT.MOBILE,
      ...SLIDER_DEFAULT.MOBILE,
    },
    TwoRows: {
      ...GLOBAL_LAYOUT.MOBILE,
      ...SLIDER_DEFAULT.MOBILE,
      [LAYOUT_MOBILE_SLIDER_NUM_OF_ROWS]: 2,
    },
  },
};

export const CONVERT_DESKTOP_TO_MOBILE = {
  [LAYOUT_GRID_NUM_OF_ROWS]: LAYOUT_MOBILE_GRID_NUM_OF_ROWS,
  [LAYOUT_GRID_NUM_OF_COLUMNS]: LAYOUT_MOBILE_GRID_NUM_OF_COLUMNS,
  [LAYOUT_ITEM_SPACING]: LAYOUT_MOBILE_ITEM_SPACING,
  [LAYOUT_SELECTED]: LAYOUT_MOBILE_SELECTED,
  [LAYOUT_RATION_SELECTED]: LAYOUT_MOBILE_RATION_SELECTED,
  [LAYOUT_SHOW_LOAD_MORE]: LAYOUT_MOBILE_SHOW_LOAD_MORE,
  [LAYOUT_GRID_TOTAL_MEDIAS_TO_SHOW]: LAYOUT_MOBILE_GRID_TOTAL_MEDIAS_TO_SHOW,
  [LAYOUT_NUM_MEDIAS_LOAD_MORE]: LAYOUT_MOBILE_NUM_MEDIAS_LOAD_MORE,
  [LAYOUT_IS_LIMIT_IMAGES]: LAYOUT_MOBILE_IS_LIMIT_IMAGES,
  [LAYOUT_SLIDER_ARROW_SIZE]: LAYOUT_MOBILE_SLIDER_ARROW_SIZE,
  [LAYOUT_SLIDER_ARROW_HORIZONTAL_PADDING]:
    LAYOUT_MOBILE_SLIDER_ARROW_HORIZONTAL_PADDING,
  [IS_NEW_LAYOUT]: IS_NEW_LAYOUT,
};

export const CONVERT_MOBILE_TO_DESKTOP = {
  [LAYOUT_MOBILE_GRID_NUM_OF_ROWS]: LAYOUT_GRID_NUM_OF_ROWS,
  [LAYOUT_MOBILE_GRID_NUM_OF_COLUMNS]: LAYOUT_GRID_NUM_OF_COLUMNS,
  [LAYOUT_MOBILE_ITEM_SPACING]: LAYOUT_ITEM_SPACING,
  [LAYOUT_MOBILE_SELECTED]: LAYOUT_SELECTED,
  [LAYOUT_MOBILE_RATION_SELECTED]: LAYOUT_RATION_SELECTED,
  [LAYOUT_MOBILE_SHOW_LOAD_MORE]: LAYOUT_SHOW_LOAD_MORE,
  [LAYOUT_MOBILE_GRID_TOTAL_MEDIAS_TO_SHOW]: LAYOUT_GRID_TOTAL_MEDIAS_TO_SHOW,
  [LAYOUT_MOBILE_NUM_MEDIAS_LOAD_MORE]: LAYOUT_NUM_MEDIAS_LOAD_MORE,
  [LAYOUT_MOBILE_IS_LIMIT_IMAGES]: LAYOUT_IS_LIMIT_IMAGES,
  [LAYOUT_MOBILE_SLIDER_ARROW_SIZE]: LAYOUT_SLIDER_ARROW_SIZE,
  [LAYOUT_MOBILE_SLIDER_ARROW_HORIZONTAL_PADDING]:
    LAYOUT_SLIDER_ARROW_HORIZONTAL_PADDING,
  [IS_NEW_LAYOUT]: IS_NEW_LAYOUT,
};
