import { ElementRoles } from '../../enums';

const COMPS_DATA = {
  header: {
    label: 'instagram-blocks.panel.elements.header',
    role: ElementRoles.HeaderContainer,
    tooltip: 'instagram-blocks.panel.elements.header.tooltip',
  },
  headerTitle: {
    label: 'instagram-blocks.panel.elements.title',
    role: ElementRoles.HeaderTitle,
  },
  headerUsername: {
    label: 'instagram-blocks.panel.elements.userName',
    role: ElementRoles.HeaderUserNameBtn,
  },
  headerHashtags: {
    label: 'instagram-blocks.panel.elements.hashtags',
    role: ElementRoles.HeaderHashtagBtn,
  },
};

export const elementsData = [
  {
    label: COMPS_DATA.header.label,
    identifier: { role: COMPS_DATA.header.role },
    index: 0,
    tooltipData: {
      content: COMPS_DATA.header.tooltip,
    },
  },
  {
    label: COMPS_DATA.headerTitle.label,
    identifier: { role: COMPS_DATA.headerTitle.role },
    index: 1,
  },
  {
    label: COMPS_DATA.headerUsername.label,
    identifier: { role: COMPS_DATA.headerUsername.role },
    index: 2,
  },
  {
    label: COMPS_DATA.headerHashtags.label,
    identifier: { role: COMPS_DATA.headerHashtags.role },
    index: 3,
  },
];
