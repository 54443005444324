import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { ApplicationError } from '@wix/error-handler/dist/types/types/ApplicationError';

interface ErrorMessage {
  title: string;
  description: string;
}

export const ERROR_CODES = {
  INVALID_TOKEN: '403',
  INVALID_TOKEN_2: '190',
  INVALID_TOKEN_3: '401',
  NOT_FOUND: 'NOT_FOUND',
  NO_POSTS: '405',
  GENERAL: '407',
  NO_POSTS_YET: 'NO_POSTS_YET',
  DEPRECATED_API_1: '2635',
  DEPRECATED_API_2: '12',
  DEPRECATED_API_3: '100',
};

export const ERRORS_MESSAGE = {
  PASSWORD_CHANGED:
    'The session has been invalidated because the user changed their password',
  SESSION_EXPIRED: 'Session has expired on',
  NOT_CONFIRMED_USER:
    'Sessions for the user are not allowed because the user is not a confirmed user.',
  NOT_AUTHORIZED_APP: 'The user has not authorized application',
  NO_PERMISSION: 'Application does not have permission for this action',
  INVALID_AUTH: 'Invalid OAuth access token',
  FETCHED_FROM_CACHE: 'Fetched from cache',
  ENTITY_NOT_FOUND: 'Entity not found',
  DEPRECATED_API_VERSION_1:
    'You are calling a deprecated version of the Ads API',
  DEPRECATED_API_VERSION_2: 'API is deprecated for versions',
  DEPRECATED_API_VERSION_3: 'Unsupported request - method type: get',
};
const TranslationStrings = [
  'default',
  'passwordChange',
  'apiIssue',
  'accountDisconnected',
  'missingPermissions',
  'entityNotFound',
  'sessionExpired',
  'notConfirmedUser',
  'invalidAuth',
  'notAuthorizedApp',
  'invalidResponse',
  'noPostsYet',
  'deprecatedPersonalAccount',
];
export const generateErrorMessage = (
  error,
  t: EditorScriptFlowAPI['translations']['t'],
): ErrorMessage => {
  try {
    const { message } = error;
    if (TranslationStrings.includes(message)) {
      const { title, description } = errorMapper(error);
      return { title: t(title), description: t(description) };
    }
    return { title: '', description: message };
  } catch (err) {
    console.error('[generateErrorMessage]', err);
    return {
      title: t('instagram-blocks.widget.emptyState.default.title'),
      description: t('instagram-blocks.widget.emptyState.default.description'),
    };
  }
};

const errorMapper = (error): ErrorMessage => {
  const { message } = error;
  console.log('Error Mapper', { error, message });
  return {
    title: `instagram-blocks.widget.emptyState.${message}.title`,
    description: `instagram-blocks.widget.emptyState.${message}.description`,
  };
};

const getErrorMessage = (description) => {
  const errorMappings = {
    [ERRORS_MESSAGE.PASSWORD_CHANGED]: 'passwordChange',
    [ERRORS_MESSAGE.SESSION_EXPIRED]: 'sessionExpired',
    [ERRORS_MESSAGE.NOT_CONFIRMED_USER]: 'notConfirmedUser',
    [ERRORS_MESSAGE.NOT_AUTHORIZED_APP]: 'notAuthorizedApp',
    [ERRORS_MESSAGE.NO_PERMISSION]: 'missingPermissions',
    [ERRORS_MESSAGE.INVALID_AUTH]: 'invalidAuth',
  };

  const matchedKey = Object.keys(errorMappings).find((key) =>
    description?.includes(key),
  );

  return matchedKey ? errorMappings[matchedKey] : null;
};

const isDeprecatedError = (description) =>
  [
    ERRORS_MESSAGE.DEPRECATED_API_VERSION_1,
    ERRORS_MESSAGE.DEPRECATED_API_VERSION_2,
    ERRORS_MESSAGE.DEPRECATED_API_VERSION_3,
  ].some((error) => description?.includes(error));

export const handleApplicationError = ({
  code,
  description,
}: ApplicationError) => {
  const message =
    getErrorMessage(description) ||
    (isDeprecatedError(description) ? 'deprecatedPersonalAccount' : 'default');
  return { message };
};
