import { FULL_WIDGET_CONTROLLER_ID } from '../../constants';

export const setLoadMoreInPgPanel = async (manifest, editorSDK) => {
  manifest.controllersStageData[
    FULL_WIDGET_CONTROLLER_ID
  ].default.connections.proGallery1.panel = {
    ...manifest.controllersStageData[FULL_WIDGET_CONTROLLER_ID].default
      .connections.proGallery1.panel,
    layout: {
      controls: {
        behaviourParams_gallery_vertical_loadMore_enable: { hidden: true },
      },
    },
  };
};
