import { getPresetNameById } from '../../constants';
import { PresetIds, PresetNames } from '../../enums';

function checkIfPreset(
  presetToCheck: PresetNames,
  presetNamesArr: PresetNames[],
) {
  for (const presetName of presetNamesArr) {
    if (presetToCheck === presetName) {
      return true;
    }
  }
  return false;
}

export function checkIfLoadMorePreset(
  preset: PresetIds,
  prevPresetId: PresetIds,
): boolean {
  const currentPresetName = getPresetNameById(preset) as PresetNames;
  const prevPresetName = getPresetNameById(prevPresetId) as PresetNames;

  const isLoadMorePresetDesktop = checkIfPreset(PresetNames.Grid2x3, [
    currentPresetName,
    prevPresetName,
  ]);
  const isLoadMorePresetMobile = checkIfPreset(PresetNames.Grid2x3_M, [
    currentPresetName,
    prevPresetName,
  ]);

  const isLoadMorePreset = isLoadMorePresetDesktop || isLoadMorePresetMobile;

  return isLoadMorePreset;
}
