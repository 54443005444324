import {
  AppManifest,
  GfppConfig,
  GfppDesktopConfig,
  GfppMobileConfig,
} from '@wix/platform-editor-sdk';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import {
  EXPERIMENTS,
  FULL_WIDGET_CONTROLLER_ID,
  mainWidgetPresets,
  mobileWidgetPresets,
} from '../../constants';
import { setLoadMoreInPgPanel } from './setLoadMoreInPgPanel';
import { configureManagementActions } from './configureManagementActions';

export const isMobileViewport = async (editorSDK) => {
  const editorMode = await editorSDK.info.getCurrentViewport();
  return editorMode.type === 'MOBILE';
};

export const modifyAppManifest = async (
  options,
  editorSDK,
  flowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  const t = flowAPI.translations.t;
  const showInstagramFeedInMyBusiness: boolean = flowAPI.experiments.enabled(
    EXPERIMENTS.SHOW_INSTAGRAM_IN_MY_BUSINESS,
  );
  const manifestBuilder = appManifestBuilder.withJsonManifest(baseManifest);
  if (showInstagramFeedInMyBusiness) {
    manifestBuilder.configureManagementActions((managementActionsBuilder) => {
      return configureManagementActions(managementActionsBuilder, t);
    });
  }
  const manifest = manifestBuilder.build();
  // Hide loadMore from pro gallery panel
  await setLoadMoreInPgPanel(manifest, editorSDK);
  // Hide elements panel on mobile
  const appStageDataDefault =
    manifest.controllersStageData![FULL_WIDGET_CONTROLLER_ID].default;
  const mobileAppGfpp = (appStageDataDefault.gfpp as GfppConfig)
    .mobile as GfppMobileConfig;
  // @ts-expect-error
  mobileAppGfpp.iconButtons.add = { behavior: 'HIDE' };
  // @ts-expect-error
  mobileAppGfpp.widgetDesign = appStageDataDefault.gfpp.desktop.widgetDesign;
  const { isClassicEditor } = flowAPI.environment;
  const isMobile = await isMobileViewport(editorSDK);

  setAppPresetsData(
    manifest,
    isMobile,
    isClassicEditor,
    flowAPI.translations.t,
  );
  return manifest;
};
export const setAppPresetsData = (
  appManifest: AppManifest,
  isMobile: boolean,
  isClassic: boolean,
  t,
) => {
  const appStageDataDefault =
    appManifest.controllersStageData![FULL_WIDGET_CONTROLLER_ID].default;
  const desktopAppGfpp = (appStageDataDefault.gfpp as GfppConfig)
    .desktop as GfppDesktopConfig;
  const mobileAppGfpp = (appStageDataDefault.gfpp as GfppConfig)
    .mobile as GfppMobileConfig;

  const presetsWeb = mainWidgetPresets(isClassic).map((preset) => {
    return { ...preset, name: t(preset.localeName) };
  });
  const presetsMobile = mobileWidgetPresets(isClassic).map((preset) => {
    return { ...preset, name: t(preset.localeName) };
  });

  if (isClassic) {
    desktopAppGfpp.widgetPresets = { presets: presetsWeb };
    // @ts-expect-error
    mobileAppGfpp.widgetPresets = { presets: presetsMobile };
  } else {
    desktopAppGfpp.widgetPresets = {
      presets: isMobile ? presetsMobile : presetsWeb,
    };
  }
};
