import { jwtDecode } from 'jwt-decode';
import { PgItemType } from '../utils/Viewer';
import {
  ButtonClickParams,
  BiConstants,
  BiEvents,
  ConnectionStatus,
  ButtonsTypeBi,
  DecodedInstance,
  AccountType,
  ParentViewType,
  BiBaseParams,
  BaseBuilderParams,
} from '.';

export const getParentView = (btnName: ButtonsTypeBi): ParentViewType => {
  let parent = 'editor';
  Object.keys(BiConstants).find((parentKey) => {
    const match = BiConstants[parentKey].find(
      (buttonName) => buttonName === btnName,
    );
    if (match) {
      parent = parentKey;
    }
  });
  return parent as ParentViewType;
};

export enum INSTANCE_KEYS {
  APP_DEF_ID = 'appDefId',
  BI_TOKEN = 'biToken',
  DEMO_MODE = 'demoMode',
  EXPIRATION_DATE = 'expirationDate',
  INSTANCE_ID = 'instanceId',
  LOGIN_ACCOUNT_ID = 'loginAccountId',
  META_SITE_ID = 'metaSiteId',
  PERMISSIONS = 'permissions',
  SIGN_DATE = 'signDate',
  SITE_MEMBER_ID = 'siteMemberId',
  SITE_OWNER_ID = 'siteOwnerId',
  UID = 'uid',
}

export const getDataFromInstance = (
  instance: string,
  type: INSTANCE_KEYS,
): string | null => {
  const decoded = instance ? jwtDecode<DecodedInstance>(instance) : null;
  return decoded?.[type] || null;
};

export const baseParamsBuilder = (instance: string): BaseBuilderParams => ({
  is_new_app: true,
  app_id: getDataFromInstance(instance, INSTANCE_KEYS.APP_DEF_ID) as string,
  uuid: getDataFromInstance(instance, INSTANCE_KEYS.UID) as string,
  msid: getDataFromInstance(instance, INSTANCE_KEYS.META_SITE_ID) as string,
  instance_id: getDataFromInstance(
    instance,
    INSTANCE_KEYS.INSTANCE_ID,
  ) as string,
});

export const bi = ({ flowAPI, app_instance }: BiBaseParams) => {
  const instance =
    ('controllerConfig' in flowAPI &&
      flowAPI.controllerConfig?.appParams?.instance) ||
    app_instance;
  // @ts-expect-error
  const { isViewer } = flowAPI.environment;
  const baseParams = baseParamsBuilder(instance as string);
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  return {
    buttonClick(
      button_name: ButtonsTypeBi,
      buttonClickParams?: ButtonClickParams,
    ) {
      const biFunction = BiEvents.getBiEventFunction('buttonClick');
      const parent_view = getParentView(button_name);
      const biParams = {
        button_type: button_name,
        parent_view,
        origin: parent_view,
        ...buttonClickParams,
        ...baseParams,
      };
      return biLogger.report(biFunction(biParams));
    },
    clickOnButtonOrLink(type: ButtonsTypeBi) {
      const biFunction = BiEvents.getBiEventFunction('clickOnButtonOrLink');
      const parent_view = getParentView(type);
      const biParams = {
        type,
        parent_view,
        origin: parent_view,
        ...baseParams,
      };
      return biLogger.report(biFunction(biParams));
    },
    instagramViewPost(type: PgItemType, isExpand = false) {
      const biFunction = BiEvents.getBiEventFunction('instagramViewPost');
      const parent_view = isExpand ? 'expand_mode' : 'main_widget';
      const biParams = {
        ...baseParams,
        gallery_type: 'posts_feed',
        type,
        parent_view,
      };
      return biLogger.report(biFunction(biParams));
    },
    appLoadedStart() {
      const biFunction = BiEvents.getBiEventFunction('appLoadedStart');
      const type = isViewer ? 'viewer' : 'editor';
      const biParams = {
        type,
        ...baseParams,
      };
      return biLogger.report(biFunction(biParams));
    },
    appLoadedEnd(isDemo: boolean) {
      const account_type: AccountType = isDemo ? 'demo' : 'user';
      const biFunction = BiEvents.getBiEventFunction('appLoadedEnd');
      const type = isViewer ? 'viewer' : 'editor';
      const biParams = {
        account_type,
        type,
        ...baseParams,
      };
      return biLogger.report(biFunction(biParams));
    },
    statusChanged(new_status: ConnectionStatus) {
      const parent_view = getParentView(new_status);
      const old_status: ConnectionStatus =
        new_status === 'connected' ? 'disconnected' : 'connected';
      const biFunction = BiEvents.getBiEventFunction('statusChanged');
      const biParams = {
        ...baseParams,
        old_status,
        new_status,
        parent_view,
      };
      return biLogger.report(biFunction(biParams));
    },
  };
};
