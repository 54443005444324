import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { HeaderStates } from '../../enums';
import { EditorSDKUtils } from './editorSdkUtils';
import { getWidgetRefs } from './getWidgetRefs';

export const setManifestState = async (
  state: HeaderStates,
  editorSDK: FlowEditorSDK,
) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const currentRefComps = await editorSDKUtils.getAllAppRefComponents();
  currentRefComps.forEach(async (comp) => {
    const { controllerRef } = await getWidgetRefs(editorSDK, comp);
    if (controllerRef) {
      await editorSDKUtils.setControllerState(controllerRef, state);
    }
  });
};
