import {
  modelProps,
  loadingProps,
  loadingProps_m,
} from '../components/Gallery and Header/model';
import { PresetIds, PresetNames } from '../enums';
import {
  LoadingPropsI,
  LoadingPropsI_m,
  WidgetLayoutPropsI,
  WidgetLayoutPropsI_m,
  WidgetPropsI,
} from '../types';
import { DEFAULT_LAYOUT_BY_PRESET } from './layout';

export const INITIAL_PROPS: WidgetPropsI = Object.entries(modelProps).reduce(
  (acc, cur) => {
    const [prop, { defaultValue }] = cur;
    return { ...acc, [prop]: defaultValue };
  },
  {} as WidgetPropsI,
);

export const getInitialLoadingProps = (
  isLoadMorePreset: boolean,
  isMobile: boolean,
): LoadingPropsI | LoadingPropsI_m => {
  const currentProps = isMobile ? loadingProps_m : loadingProps;
  const props = Object.entries(currentProps).reduce((acc, cur) => {
    const [propName, { defaultValue, loadingValue }] = cur;
    return {
      ...acc,
      [propName]: isLoadMorePreset ? loadingValue : defaultValue,
    };
  }, {} as LoadingPropsI | LoadingPropsI_m);
  return props;
};

export const getInitialLayoutProps = (
  presetId: string,
  isMobile: boolean,
):
  | (LoadingPropsI & WidgetLayoutPropsI)
  | (LoadingPropsI_m & WidgetLayoutPropsI_m) => {
  const initialLayout = isMobile
    ? DEFAULT_LAYOUT_BY_PRESET.MOBILE
    : DEFAULT_LAYOUT_BY_PRESET.DESKTOP;

  console.log('initialLayout', initialLayout, presetId);

  switch (presetId) {
    case PresetIds.Grid2x3:
    case PresetNames.Grid2x3:
    case PresetIds.Grid2x3_M:
    case PresetNames.Grid2x3_M:
      return initialLayout.Grid;
    case PresetIds.OneRow:
    case PresetNames.OneRow:
    case PresetIds.OneRow_M:
    case PresetNames.OneRow_M:
      return initialLayout.OneRow;
    case PresetIds.SideBySide:
    case PresetNames.SideBySide:
    case PresetNames.SideBySide_RTL:
    case PresetIds.SideBySide_RTL:
    case PresetIds.SideBySide_M:
    case PresetNames.SideBySide_M:
      return initialLayout.SideBySide;
    case PresetIds.TwoRows:
    case PresetNames.TwoRows:
    case PresetIds.TwoRows_M:
    case PresetNames.TwoRows_M:
      return initialLayout.TwoRows;
    default:
      return initialLayout.OneRow;
  }
};
