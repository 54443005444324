import { TFunction } from '@wix/yoshi-flow-editor';
import { elementsData } from './elementsPanelData';

export const createElementsData = (t: TFunction, isExpand: boolean) =>
  elementsData.map((el) => {
    const element = {
      ...el,
      label: t(el.label),
    };
    if (el.tooltipData?.content) {
      element.tooltipData = { content: t(el.tooltipData.content) };
    }
    return element;
  });
