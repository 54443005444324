import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorSDKUtils } from './editorSdkUtils';
import { ElementRoles } from '../../enums';

export async function getWidgetRefs(
  editorSDK: EditorSDK,
  rootWidgetRef: ComponentRef,
) {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const [controllerRef] = await editorSDKUtils.getChildren(rootWidgetRef);
  if (controllerRef) {
    const [mainRef] = await editorSDKUtils.findAllByRole(
      controllerRef,
      ElementRoles.Widget,
    );
    return { mainRef, controllerRef };
  }
  return {};
}
