import { EditorReadyOptions, EditorType } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorSDKUtils } from './editorSdkUtils';
import { presetListenerCallback } from './presetListenerCallback';
import { createLightboxWithWidget, navigateToLightboxEditor } from './lightbox';
import { createGfppActions } from './gfppUtils';
import {
  FULL_WIDGET_ID,
  getInitialLoadingProps,
  getInitialLayoutProps,
  INITIAL_PROPS,
  mainWidgetOptions,
} from '../../constants';
import { ActionIdsType, WidgetPropsI } from '../../types';
import { onWidgetDeletion } from './onLastWidgetDeletion';
import { initDeviceSwitchEventListener } from './onDeviceChanged';
import { isMobileViewport } from './modifyAppManifest';
import { PresetHeights, PresetIds } from '../../enums';

export const editorAppEventListener = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options: EditorReadyOptions,
) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const { t } = flowAPI.translations;
  await initDeviceSwitchEventListener({ editorSDK });
  await editorSDKUtils.addActionBusinessClicked(async (event) => {
    const { actionId } = event.detail;
    if (actionId === 'addWidget') {
      const compRef = await editorSDKUtils.addWidget(mainWidgetOptions);
      const response = await editorSDKUtils.setWidgetHeight(
        compRef,
        PresetHeights.OneRow,
      );
      return response;
    }
  });

  await editorSDKUtils.addComponentGfppClicked(async (event) => {
    const { componentRef, id } = event.detail;
    const actionId = id as ActionIdsType;
    const {
      openPgCustomSetting,
      openExpandSettings,
      openAccountSettings,
      openButtonPanel,
    } = await createGfppActions({
      editorSDK,
      componentRef,
      options,
      flowAPI,
    });
    switch (actionId) {
      case 'openButtonWithoutText':
        return openButtonPanel({});
      case 'openSettingsPanel':
        return openAccountSettings(true);
      case 'openCustomSettings':
        await openPgCustomSetting();
        break;
      case 'settingsPGExpand':
        await openExpandSettings();
        break;
      case 'loadMoreSettings':
        await openButtonPanel({ icon: false, label: false });
        break;
      case 'openEditorLightbox':
        await navigateToLightboxEditor(editorSDK);
        break;
      default:
        console.log('Could not find right comp gfpp', { id, componentRef });
    }
  });

  await editorSDKUtils.addWidgetGfppClickListener(async (event) => {
    const { id, componentRef } = event.detail;
    // const msid = await editorSDKUtils.getMetaSiteId();
    // const instance_id = await editorSDKUtils.getInstanceId();
    // const biLogger = bi({ flowAPI, msid, instance_id });
    // const biBtnClick = biLogger.sitePromoButtonClick;
    const { openAccountSettings, openElements, openLayout, openLayoutPanel } =
      await createGfppActions({
        editorSDK,
        componentRef,
        options,
        flowAPI,
      });
    switch (id) {
      case 'openSettingsPanel':
        return openAccountSettings();
      case 'openElementsPanel':
        return openElements({ t });
      // biBtnClick('widget_elements');
      case 'openLayoutPanel':
        return openLayout();
      // biBtnClick('widget_layout');
      case 'openCustomLayoutPanel':
        return openLayoutPanel();
      case 'openPresetsPanel':
        return editorSDK.editor.openPanel(
          'compPanels.panels.Widget.widgetPresetsPanel',
          {
            selectedComponent: [componentRef],
            frameType: 'compPanelFrame',
          },
          false,
        );
      default:
        console.log('Could not find right gfpp', { id, componentRef });
    }
  });

  await editorSDKUtils.onDeleteWidgetListener(async () => {
    return onWidgetDeletion(editorSDKUtils);
  });

  await editorSDKUtils.onResetWidgetListener(async (event) => {
    const { componentRef } = event.detail;
    const [childRef] = await editorSDKUtils.getChildren(componentRef);
    const preset = await editorSDKUtils.getPreset(childRef);
    const currentProps = (await editorSDKUtils.getWidgetProps(
      childRef,
    )) as WidgetPropsI;
    const isResponsive = options.origin.type === EditorType.Responsive;
    const isMobile = await isMobileViewport(editorSDK);

    const isLoadMorePreset =
      preset.layout === PresetIds.Grid2x3 ||
      preset.layout === PresetIds.Grid2x3_M;

    const loadingProps = getInitialLoadingProps(isLoadMorePreset, isMobile);
    const layoutProps =
      (preset.layout && getInitialLayoutProps(preset.layout, isMobile)) || {};

    await editorSDKUtils.setWidgetProps({
      componentRef: childRef,
      newProps: { ...currentProps, ...loadingProps, ...layoutProps },
    });
  });

  await editorSDKUtils.onAddWidgetListener(async (event) => {
    const { compRef } = event.detail;
    const [compStructure] = await editorSDKUtils.getCompStructure(compRef);
    const isResponsive = options.origin.type === EditorType.Responsive;
    if (compStructure?.data?.widgetId === FULL_WIDGET_ID) {
      setMainWidgetProps();
      return createLightboxWithWidget(editorSDK, isResponsive, t);
    }
    async function setMainWidgetProps() {
      const MAX_RETRIES = 10;
      let retries = 0;

      async function trySetProps() {
        const [childRef] = await editorSDKUtils.getChildren(compRef);

        if (childRef) {
          const props = await editorSDKUtils.getWidgetProps(childRef);
          const { accountId } = props;
          await editorSDKUtils.setWidgetProps({
            componentRef: childRef,
            newProps: {
              ...INITIAL_PROPS,
              isClassic: !isResponsive,
              ...(accountId ? { accountId } : {}),
            },
          });
        } else if (retries < MAX_RETRIES) {
          retries++;
          await new Promise((resolve) => setTimeout(resolve, 300));
          await trySetProps();
        } else {
          console.error('Max retries reached. Unable to set widget props.');
        }
      }

      await trySetProps();
    }
  });

  await editorSDKUtils.addGlobalDesignPresetChanged(async (event) => {
    return presetListenerCallback({ event, editorSDK, flowAPI });
  });
};
