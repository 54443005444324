import {
  CLICK_ACTION,
  ElementRoles,
  LayoutFeed,
  PgCustomOnclick,
} from '../../enums';
import { WidgetPropsI } from '../../types';

const actionMap = {
  [PgCustomOnclick.OpenExpand]: CLICK_ACTION.EXPAND,
  [PgCustomOnclick.OpenLink]: CLICK_ACTION.LINK,
  [PgCustomOnclick.NothingHappens]: CLICK_ACTION.NOTHING,
};
const getClickAction = (onClickAction: PgCustomOnclick | undefined) => {
  if (onClickAction !== undefined && actionMap[onClickAction]) {
    return {
      behaviourParams_item_clickAction: actionMap[onClickAction],
    };
  }
  return {};
};
export const setProGalleryLayouts = (
  props: WidgetPropsI,
  $w,
  isMobile: boolean,
) => {
  console.log('viewer props', props, isMobile, props.sliderNumOfRows_m);
  const { onClickAction } = props;
  const clickLayouts = getClickAction(onClickAction);
  const manualStyleParams = {
    ...clickLayouts,
    layoutParams_structure_galleryLayout: 2,
    layoutParams_structure_numberOfColumns: Number(
      isMobile ? props.gridNumOfColumns_m : props.gridNumOfColumns,
    ),
    layoutParams_structure_scrollDirection:
      (isMobile ? props.layoutSelected_m : props.layoutSelected) ===
      LayoutFeed.Grid
        ? 'VERTICAL'
        : 'HORIZONTAL',
    layoutParams_structure_itemSpacing: Number(
      isMobile ? props.itemSpacing_m : props.itemSpacing,
    ),
    layoutParams_crop_ratios: Number(
      isMobile ? props.ratioSelected_m : props.ratioSelected,
    ),
    layoutParams_navigationArrows_size: Number(
      isMobile ? props.sliderArrowSize_m : props.sliderArrowSize,
    ),
    layoutParams_navigationArrows_padding: Number(
      isMobile
        ? props.sliderArrowHorizontalPadding_m
        : props.sliderArrowHorizontalPadding,
    ),
    layoutParams_navigationArrows_container_type: 'BOX',
    layoutParams_structure_numberOfGridRows: Number(
      isMobile ? props.sliderNumOfRows_m : props.sliderNumOfRows,
    ),
  };

  $w(`#${ElementRoles.ProGallery}`).manualStyleParams = manualStyleParams;
};
export const setProGalleryClickAction = (props: WidgetPropsI, $w) => {
  const { onClickAction } = props;
  const clickLayouts = getClickAction(onClickAction);
  $w(`#${ElementRoles.ProGallery}`).manualStyleParams = {
    ...clickLayouts,
  };
};
