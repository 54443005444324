export const GalleryCoverConfig = {
  // controllers_stylableOptions_gallery: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_container: {
  //   hidden: true,
  // },

  // controllers_stylableOptions_item: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_itemHover: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_itemOverlay: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_itemOverlayHover: {
  //   hidden: true,
  // },
  controllers_stylableOptions_itemContent: {
    hidden: true,
  },
  controllers_stylableOptions_itemContentHover: {
    hidden: true,
  },
  // controllers_stylableOptions_innerTitle: {
  //   hidden: true,
  // },
  controllers_experimental_useNewInfo: {
    hidden: true,
  },
  controllers_stylableOptions_innerDescription: {
    hidden: true,
  },
  controllers_stylableOptions_externalTitle: {
    hidden: true,
  },
  controllers_stylableOptions_externalDescription: {
    hidden: true,
  },
  controllers_stylableOptions_innerTitleRegular: {
    hidden: true,
  },
  controllers_stylableOptions_innerTitleHover: {
    hidden: true,
  },
  controllers_stylableOptions_innerDescriptionRegular: {
    hidden: true,
  },
  controllers_stylableOptions_innerDescriptionHover: {
    hidden: true,
  },
  controllers_stylableOptions_innerCustomButtonRegular: {
    hidden: true,
  },
  controllers_stylableOptions_innerCustomButtonHover: {
    hidden: true,
  },
  // controllers_stylableOptions_externalTitleRegular: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_externalTitleHover: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_externalDescriptionRegular: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_externalDescriptionHover: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_externalCustomButtonRegular: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_externalCustomButtonHover: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_loadMore: {
  //   hidden: true,
  // },

  // controllers_stylableOptions_arrowContainer: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_arrowContainerHover: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_arrowIcon: {
  //   hidden: true,
  // },
  // controllers_stylableOptions_arrowIconHover: {
  //   hidden: true,
  // },
  controllers_stylableOptions_externalInfoSocialButtonRegular: {
    hidden: true,
  },
  controllers_stylableOptions_externalInfoSocialButtonHover: {
    hidden: true,
  },
  controllers_stylableOptions_internalInfoSocialButtonRegular: {
    hidden: true,
  },
  controllers_stylableOptions_internalInfoSocialButtonHover: {
    hidden: true,
  },
};
