import { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';
import { EditorSDKUtils } from './editorSdkUtils';
import { mainWidgetOptions } from '../../constants';
import { ElementRoles, PresetHeights } from '../../enums';

export const installWidget = async (editorSDK: FlowEditorSDK, t: TFunction) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  await editorSDKUtils.reloadManifest();
  const compRef = await editorSDKUtils.addWidget(mainWidgetOptions);
  const [controllerRef] = await editorSDKUtils.getChildren(compRef);
  const [buttonsRef] = await editorSDK.document.components.findAllByRole(
    'token',
    {
      controllerRef,
      role: ElementRoles.LoadMore,
    },
  );
  if (buttonsRef) {
    await editorSDK.document.components.data.update('token', {
      componentRef: buttonsRef,
      data: {
        label: t('instagram-blocks.widget.demo.loadMoreBtn'),
      },
    });
  }
  await editorSDKUtils.setWidgetHeight(compRef, PresetHeights.OneRow);
};
